import React, { Fragment, useCallback } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { faEye } from '@fortawesome/free-solid-svg-icons/faEye';
import { Icon, Switch } from '@appcues/sonar';
import useAnalytics from 'ext/lib/hooks/use-analytics';
import {
  DebouncedInput,
  FieldSet,
  Label,
  LightButton,
} from 'ext/components/ui';
import { selectActiveLocale } from 'entities/locales';
import { localizeContent } from 'lib/localization';
import { selectBlockContent } from 'lib/selectors';
import { selectUserPreferences } from 'entities/user-preferences';
import { selectPreviewType, updatePreview } from 'entities/user-interface';
import { THEMES } from 'lib/user-preferences';
import { ERROR_COLOR, ERROR_MESSAGE } from 'lib/user-interface';
import { BlockContentShape } from 'entities/block';
import {
  DEFAULT_COLORS,
  GroupedFieldSet,
  GroupedField,
  HelpLabel,
  useStyleSettings,
} from 'components/SideBarSettings/Shared';
import ColorInput from 'components/ColorInput';

export const RequiredField = ({
  blockContent,
  localeId,
  theme,
  selectPreview,
  onPreviewUpdate,
  onRequiredChange,
  onErrorLabelChange,
}) => {
  const { track } = useAnalytics();

  const { content } = localizeContent(blockContent, localeId) ?? {};
  const { id: blockId, required, errorLabel } = content ?? {};
  const { text, style } = errorLabel ?? {};

  const errorColor = selectPreview(blockId, ERROR_COLOR);
  const errorMessage = selectPreview(blockId, ERROR_MESSAGE);

  const dismissPreview = (...previewTypes) =>
    previewTypes.map(type =>
      onPreviewUpdate({ blockId, previewType: type, show: false })
    );

  const handleToggleRequired = () => {
    track('Mobile Builder interaction', {
      name: `Clicked Required Toggle ${required ? 'Off' : 'On'}`,
      component: 'RequiredField',
    });

    if (required) dismissPreview(ERROR_COLOR, ERROR_MESSAGE);
    onRequiredChange(!required);
  };

  const handleChange = useCallback(
    patch => {
      onErrorLabelChange({ ...errorLabel, style: patch });
    },
    [errorLabel, onErrorLabelChange]
  );

  const [valueFor, handleChangeFor] = useStyleSettings({
    onChange: handleChange,
    style,
    theme,
  });

  const handleErrorMessageChange = ({ target: { value } }) => {
    onErrorLabelChange({ ...errorLabel, text: value });
  };

  return (
    <>
      <FieldSet>
        <Switch
          id="required"
          checked={required}
          onCheckedChange={handleToggleRequired}
          fullWidth
        >
          <Switch.Label htmlFor="required">Required field</Switch.Label>
        </Switch>
      </FieldSet>

      {required && (
        <>
          <GroupedFieldSet>
            <GroupedField>
              <Label htmlFor="error-color">
                Error color
                <HelpLabel>
                  Option to set the color for question <br />
                  text and input controls when <br />
                  submission is incomplete
                </HelpLabel>
              </Label>
              <ColorInput
                id="error-color"
                color={valueFor.foregroundColor}
                placeholder={DEFAULT_COLORS.requiredFieldColor}
                onChange={e =>
                  handleChangeFor.foregroundColor(e, 'requiredFieldColor')
                }
              />
            </GroupedField>

            <GroupedField>
              <LightButton
                aria-label="Preview error color"
                aria-pressed={!!errorColor.show}
                kind="tertiary"
                onClick={() =>
                  onPreviewUpdate({
                    blockId,
                    previewType: errorColor.type ?? ERROR_COLOR,
                    show: !(errorColor.show ?? false),
                  })
                }
              >
                <Icon icon={faEye} size="small" />
              </LightButton>
            </GroupedField>
          </GroupedFieldSet>

          <GroupedFieldSet>
            <GroupedField>
              <Label htmlFor="error-message">
                Error message
                <HelpLabel>
                  Option to set the error <br />
                  message shown when <br />
                  submission is incomplete
                </HelpLabel>
              </Label>
              <DebouncedInput
                id="error-message"
                defaultValue={text}
                onChange={handleErrorMessageChange}
                placeholder="Optional"
                type="text"
              />
            </GroupedField>

            <GroupedField>
              <LightButton
                aria-label="Preview error message"
                aria-pressed={!!errorMessage.show}
                kind="tertiary"
                onClick={() =>
                  onPreviewUpdate({
                    blockId,
                    previewType: errorMessage.type ?? ERROR_MESSAGE,
                    show: !(errorMessage.show ?? false),
                  })
                }
              >
                <Icon icon={faEye} size="small" />
              </LightButton>
            </GroupedField>
          </GroupedFieldSet>
        </>
      )}
    </>
  );
};

RequiredField.propTypes = {
  blockContent: BlockContentShape,
  localeId: PropTypes.string,
  selectPreview: PropTypes.func,
  theme: PropTypes.oneOf(THEMES),
  onPreviewUpdate: PropTypes.func,
  onRequiredChange: PropTypes.func,
  onErrorLabelChange: PropTypes.func,
};

const mapStateToProps = state => {
  const blockContent = selectBlockContent(state);
  const { id: localeId } = selectActiveLocale(state) ?? {};
  const { theme } = selectUserPreferences(state) ?? {};
  const selectPreview = (blockId, previewType) =>
    selectPreviewType(state, { blockId, previewType });

  return {
    blockContent,
    localeId,
    theme,
    selectPreview,
  };
};

const mapDispatchToProps = {
  onPreviewUpdate: updatePreview,
};

export default connect(mapStateToProps, mapDispatchToProps)(RequiredField);
