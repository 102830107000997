"use strict";
var __create = Object.create;
var __defProp = Object.defineProperty;
var __getOwnPropDesc = Object.getOwnPropertyDescriptor;
var __getOwnPropNames = Object.getOwnPropertyNames;
var __getProtoOf = Object.getPrototypeOf;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __commonJS = (cb, mod) => function __require() {
  return mod || (0, cb[__getOwnPropNames(cb)[0]])((mod = { exports: {} }).exports, mod), mod.exports;
};
var __export = (target, all) => {
  for (var name in all)
    __defProp(target, name, { get: all[name], enumerable: true });
};
var __copyProps = (to, from, except, desc) => {
  if (from && typeof from === "object" || typeof from === "function") {
    for (let key of __getOwnPropNames(from))
      if (!__hasOwnProp.call(to, key) && key !== except)
        __defProp(to, key, { get: () => from[key], enumerable: !(desc = __getOwnPropDesc(from, key)) || desc.enumerable });
  }
  return to;
};
var __toESM = (mod, isNodeMode, target) => (target = mod != null ? __create(__getProtoOf(mod)) : {}, __copyProps(
  // If the importer is in node compatibility mode or this is not an ESM
  // file that has been converted to a CommonJS file using a Babel-
  // compatible transform (i.e. "__esModule" has not been set), then set
  // "default" to the CommonJS "module.exports" for node compatibility.
  isNodeMode || !mod || !mod.__esModule ? __defProp(target, "default", { value: mod, enumerable: true }) : target,
  mod
));
var __toCommonJS = (mod) => __copyProps(__defProp({}, "__esModule", { value: true }), mod);

// ../../node_modules/@fortawesome/pro-solid-svg-icons/faXmark.js
var require_faXmark = __commonJS({
  "../../node_modules/@fortawesome/pro-solid-svg-icons/faXmark.js"(exports) {
    "use strict";
    Object.defineProperty(exports, "__esModule", { value: true });
    var prefix = "fas";
    var iconName = "xmark";
    var width = 384;
    var height = 512;
    var aliases = [128473, 10005, 10006, 10060, 215, "close", "multiply", "remove", "times"];
    var unicode = "f00d";
    var svgPathData = "M342.6 150.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L192 210.7 86.6 105.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L146.7 256 41.4 361.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L192 301.3 297.4 406.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L237.3 256 342.6 150.6z";
    exports.definition = {
      prefix,
      iconName,
      icon: [
        width,
        height,
        aliases,
        unicode,
        svgPathData
      ]
    };
    exports.faXmark = exports.definition;
    exports.prefix = prefix;
    exports.iconName = iconName;
    exports.width = width;
    exports.height = height;
    exports.ligatures = aliases;
    exports.unicode = unicode;
    exports.svgPathData = svgPathData;
    exports.aliases = aliases;
  }
});

// ../../node_modules/@fortawesome/pro-solid-svg-icons/faImage.js
var require_faImage = __commonJS({
  "../../node_modules/@fortawesome/pro-solid-svg-icons/faImage.js"(exports) {
    "use strict";
    Object.defineProperty(exports, "__esModule", { value: true });
    var prefix = "fas";
    var iconName = "image";
    var width = 512;
    var height = 512;
    var aliases = [];
    var unicode = "f03e";
    var svgPathData = "M0 96C0 60.7 28.7 32 64 32H448c35.3 0 64 28.7 64 64V416c0 35.3-28.7 64-64 64H64c-35.3 0-64-28.7-64-64V96zM323.8 202.5c-4.5-6.6-11.9-10.5-19.8-10.5s-15.4 3.9-19.8 10.5l-87 127.6L170.7 297c-4.6-5.7-11.5-9-18.7-9s-14.2 3.3-18.7 9l-64 80c-5.8 7.2-6.9 17.1-2.9 25.4s12.4 13.6 21.6 13.6h96 32H424c8.9 0 17.1-4.9 21.2-12.8s3.6-17.4-1.4-24.7l-120-176zM112 192a48 48 0 1 0 0-96 48 48 0 1 0 0 96z";
    exports.definition = {
      prefix,
      iconName,
      icon: [
        width,
        height,
        aliases,
        unicode,
        svgPathData
      ]
    };
    exports.faImage = exports.definition;
    exports.prefix = prefix;
    exports.iconName = iconName;
    exports.width = width;
    exports.height = height;
    exports.ligatures = aliases;
    exports.unicode = unicode;
    exports.svgPathData = svgPathData;
    exports.aliases = aliases;
  }
});

// ../../node_modules/@fortawesome/pro-regular-svg-icons/faCirclePlus.js
var require_faCirclePlus = __commonJS({
  "../../node_modules/@fortawesome/pro-regular-svg-icons/faCirclePlus.js"(exports) {
    "use strict";
    Object.defineProperty(exports, "__esModule", { value: true });
    var prefix = "far";
    var iconName = "circle-plus";
    var width = 512;
    var height = 512;
    var aliases = ["plus-circle"];
    var unicode = "f055";
    var svgPathData = "M256 48a208 208 0 1 1 0 416 208 208 0 1 1 0-416zm0 464A256 256 0 1 0 256 0a256 256 0 1 0 0 512zM232 344c0 13.3 10.7 24 24 24s24-10.7 24-24V280h64c13.3 0 24-10.7 24-24s-10.7-24-24-24H280V168c0-13.3-10.7-24-24-24s-24 10.7-24 24v64H168c-13.3 0-24 10.7-24 24s10.7 24 24 24h64v64z";
    exports.definition = {
      prefix,
      iconName,
      icon: [
        width,
        height,
        aliases,
        unicode,
        svgPathData
      ]
    };
    exports.faCirclePlus = exports.definition;
    exports.prefix = prefix;
    exports.iconName = iconName;
    exports.width = width;
    exports.height = height;
    exports.ligatures = aliases;
    exports.unicode = unicode;
    exports.svgPathData = svgPathData;
    exports.aliases = aliases;
  }
});

// ../../node_modules/@fortawesome/pro-regular-svg-icons/faUpload.js
var require_faUpload = __commonJS({
  "../../node_modules/@fortawesome/pro-regular-svg-icons/faUpload.js"(exports) {
    "use strict";
    Object.defineProperty(exports, "__esModule", { value: true });
    var prefix = "far";
    var iconName = "upload";
    var width = 512;
    var height = 512;
    var aliases = [];
    var unicode = "f093";
    var svgPathData = "M280 360c0 13.3-10.7 24-24 24s-24-10.7-24-24V81.9l-95 95c-9.4 9.4-24.6 9.4-33.9 0s-9.4-24.6 0-33.9L239 7c9.4-9.4 24.6-9.4 33.9 0L409 143c9.4 9.4 9.4 24.6 0 33.9s-24.6 9.4-33.9 0l-95-95V360zm32-8V304H448c35.3 0 64 28.7 64 64v80c0 35.3-28.7 64-64 64H64c-35.3 0-64-28.7-64-64V368c0-35.3 28.7-64 64-64H200v48H64c-8.8 0-16 7.2-16 16v80c0 8.8 7.2 16 16 16H448c8.8 0 16-7.2 16-16V368c0-8.8-7.2-16-16-16H312zm72 56a24 24 0 1 1 48 0 24 24 0 1 1 -48 0z";
    exports.definition = {
      prefix,
      iconName,
      icon: [
        width,
        height,
        aliases,
        unicode,
        svgPathData
      ]
    };
    exports.faUpload = exports.definition;
    exports.prefix = prefix;
    exports.iconName = iconName;
    exports.width = width;
    exports.height = height;
    exports.ligatures = aliases;
    exports.unicode = unicode;
    exports.svgPathData = svgPathData;
    exports.aliases = aliases;
  }
});

// ../../node_modules/@fortawesome/free-regular-svg-icons/faImages.js
var require_faImages = __commonJS({
  "../../node_modules/@fortawesome/free-regular-svg-icons/faImages.js"(exports) {
    "use strict";
    Object.defineProperty(exports, "__esModule", { value: true });
    var prefix = "far";
    var iconName = "images";
    var width = 576;
    var height = 512;
    var aliases = [];
    var unicode = "f302";
    var svgPathData = "M160 80H512c8.8 0 16 7.2 16 16V320c0 8.8-7.2 16-16 16H490.8L388.1 178.9c-4.4-6.8-12-10.9-20.1-10.9s-15.7 4.1-20.1 10.9l-52.2 79.8-12.4-16.9c-4.5-6.2-11.7-9.8-19.4-9.8s-14.8 3.6-19.4 9.8L175.6 336H160c-8.8 0-16-7.2-16-16V96c0-8.8 7.2-16 16-16zM96 96V320c0 35.3 28.7 64 64 64H512c35.3 0 64-28.7 64-64V96c0-35.3-28.7-64-64-64H160c-35.3 0-64 28.7-64 64zM48 120c0-13.3-10.7-24-24-24S0 106.7 0 120V344c0 75.1 60.9 136 136 136H456c13.3 0 24-10.7 24-24s-10.7-24-24-24H136c-48.6 0-88-39.4-88-88V120zm208 24a32 32 0 1 0 -64 0 32 32 0 1 0 64 0z";
    exports.definition = {
      prefix,
      iconName,
      icon: [
        width,
        height,
        aliases,
        unicode,
        svgPathData
      ]
    };
    exports.faImages = exports.definition;
    exports.prefix = prefix;
    exports.iconName = iconName;
    exports.width = width;
    exports.height = height;
    exports.ligatures = aliases;
    exports.unicode = unicode;
    exports.svgPathData = svgPathData;
    exports.aliases = aliases;
  }
});

// ../../node_modules/@fortawesome/pro-solid-svg-icons/faTriangleExclamation.js
var require_faTriangleExclamation = __commonJS({
  "../../node_modules/@fortawesome/pro-solid-svg-icons/faTriangleExclamation.js"(exports) {
    "use strict";
    Object.defineProperty(exports, "__esModule", { value: true });
    var prefix = "fas";
    var iconName = "triangle-exclamation";
    var width = 512;
    var height = 512;
    var aliases = [9888, "exclamation-triangle", "warning"];
    var unicode = "f071";
    var svgPathData = "M256 32c14.2 0 27.3 7.5 34.5 19.8l216 368c7.3 12.4 7.3 27.7 .2 40.1S486.3 480 472 480H40c-14.3 0-27.6-7.7-34.7-20.1s-7-27.8 .2-40.1l216-368C228.7 39.5 241.8 32 256 32zm0 128c-13.3 0-24 10.7-24 24V296c0 13.3 10.7 24 24 24s24-10.7 24-24V184c0-13.3-10.7-24-24-24zm32 224a32 32 0 1 0 -64 0 32 32 0 1 0 64 0z";
    exports.definition = {
      prefix,
      iconName,
      icon: [
        width,
        height,
        aliases,
        unicode,
        svgPathData
      ]
    };
    exports.faTriangleExclamation = exports.definition;
    exports.prefix = prefix;
    exports.iconName = iconName;
    exports.width = width;
    exports.height = height;
    exports.ligatures = aliases;
    exports.unicode = unicode;
    exports.svgPathData = svgPathData;
    exports.aliases = aliases;
  }
});

// ../../node_modules/@fortawesome/pro-solid-svg-icons/faHexagonExclamation.js
var require_faHexagonExclamation = __commonJS({
  "../../node_modules/@fortawesome/pro-solid-svg-icons/faHexagonExclamation.js"(exports) {
    "use strict";
    Object.defineProperty(exports, "__esModule", { value: true });
    var prefix = "fas";
    var iconName = "hexagon-exclamation";
    var width = 512;
    var height = 512;
    var aliases = [];
    var unicode = "e417";
    var svgPathData = "M17.1 292c-12.9-22.3-12.9-49.7 0-72L105.4 67.1c12.9-22.3 36.6-36 62.4-36H344.3c25.7 0 49.5 13.7 62.4 36L494.9 220c12.9 22.3 12.9 49.7 0 72L406.6 444.9c-12.9 22.3-36.6 36-62.4 36H167.7c-25.7 0-49.5-13.7-62.4-36L17.1 292zM256 128c-13.3 0-24 10.7-24 24V264c0 13.3 10.7 24 24 24s24-10.7 24-24V152c0-13.3-10.7-24-24-24zm32 224a32 32 0 1 0 -64 0 32 32 0 1 0 64 0z";
    exports.definition = {
      prefix,
      iconName,
      icon: [
        width,
        height,
        aliases,
        unicode,
        svgPathData
      ]
    };
    exports.faHexagonExclamation = exports.definition;
    exports.prefix = prefix;
    exports.iconName = iconName;
    exports.width = width;
    exports.height = height;
    exports.ligatures = aliases;
    exports.unicode = unicode;
    exports.svgPathData = svgPathData;
    exports.aliases = aliases;
  }
});

// ../../node_modules/@fortawesome/pro-solid-svg-icons/faTrashCan.js
var require_faTrashCan = __commonJS({
  "../../node_modules/@fortawesome/pro-solid-svg-icons/faTrashCan.js"(exports) {
    "use strict";
    Object.defineProperty(exports, "__esModule", { value: true });
    var prefix = "fas";
    var iconName = "trash-can";
    var width = 448;
    var height = 512;
    var aliases = [61460, "trash-alt"];
    var unicode = "f2ed";
    var svgPathData = "M135.2 17.7C140.6 6.8 151.7 0 163.8 0H284.2c12.1 0 23.2 6.8 28.6 17.7L320 32h96c17.7 0 32 14.3 32 32s-14.3 32-32 32H32C14.3 96 0 81.7 0 64S14.3 32 32 32h96l7.2-14.3zM32 128H416V448c0 35.3-28.7 64-64 64H96c-35.3 0-64-28.7-64-64V128zm96 64c-8.8 0-16 7.2-16 16V432c0 8.8 7.2 16 16 16s16-7.2 16-16V208c0-8.8-7.2-16-16-16zm96 0c-8.8 0-16 7.2-16 16V432c0 8.8 7.2 16 16 16s16-7.2 16-16V208c0-8.8-7.2-16-16-16zm96 0c-8.8 0-16 7.2-16 16V432c0 8.8 7.2 16 16 16s16-7.2 16-16V208c0-8.8-7.2-16-16-16z";
    exports.definition = {
      prefix,
      iconName,
      icon: [
        width,
        height,
        aliases,
        unicode,
        svgPathData
      ]
    };
    exports.faTrashCan = exports.definition;
    exports.prefix = prefix;
    exports.iconName = iconName;
    exports.width = width;
    exports.height = height;
    exports.ligatures = aliases;
    exports.unicode = unicode;
    exports.svgPathData = svgPathData;
    exports.aliases = aliases;
  }
});

// src/index.ts
var index_exports = {};
__export(index_exports, {
  Context: () => Context_default,
  ImageryGallery: () => Gallery,
  ImageryProvider: () => Provider,
  ImageryUploader: () => Uploader,
  useImagery: () => useImagery
});
module.exports = __toCommonJS(index_exports);

// src/components/Gallery/Gallery.tsx
var import_react2 = require("react");
var import_faXmark = __toESM(require_faXmark());
var import_sonar3 = require("@appcues/sonar");

// src/context/Context.tsx
var import_react = require("react");
var Context = (0, import_react.createContext)(void 0);
var useImagery = () => {
  const context = (0, import_react.useContext)(Context);
  if (!context) {
    throw new Error("useImagery must be used within an ImageryProvider");
  }
  return context;
};
var Context_default = Context;

// src/components/Gallery/Card.tsx
var import_styled_components = __toESM(require("styled-components"));
var import_faImage = __toESM(require_faImage());
var import_sonar = require("@appcues/sonar");

// src/components/Gallery/gallery-helpers.ts
var formatDate = (date, locale = "en-US") => new Intl.DateTimeFormat(locale, {
  year: "numeric",
  month: "short",
  day: "numeric"
}).format(new Date(date));
var formatSize = (size) => `${(size / 1e3).toFixed(2)} KB`;

// src/components/Gallery/Card.tsx
var import_jsx_runtime = require("react/jsx-runtime");
var InvalidMessage = import_styled_components.default.div`
  position: absolute;
  display: none;
  min-width: 210px;
  text-align: center;
  color: var(--foreground-primary);
  background-color: var(--background-default-base);
  padding: var(--spacing-small);
  border: 1px solid var(--border-input-default);
  border-radius: var(--border-radius-small);
`;
var Root = import_styled_components.default.article`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  height: 220px;
  min-height: auto;
  background: var(--background-level-1);
  border: 1px solid var(--border-input-default);
  border-radius: var(--border-radius-small);
  overflow: hidden;
  transition: opacity 0.2s, outline 0.2s;

  cursor: ${({ isDisabled }) => isDisabled ? "not-allowed" : "pointer"};
  opacity: ${({ isDisabled }) => isDisabled ? 0.5 : 1};

  &:hover {
    outline: ${({ isDisabled }) => isDisabled ? "none" : "1px solid var(--border-input-hover)"};

    ${InvalidMessage} {
      display: ${({ isDisabled }) => isDisabled ? "block" : "none"};
    }
  }
`;
var ImageWrapper = import_styled_components.default.div`
  position: relative;
  display: flex;
  height: 150px;
  align-items: center;
  justify-content: center;
  background-color: var(--color-neutral-100);
`;
var ImageSource = import_styled_components.default.img`
  display: flex;
  width: 100%;
  height: 100%;
  aspect-ratio: 1;
  object-fit: contain;
  object-position: center;
`;
var Title = (0, import_styled_components.default)(import_sonar.Text)`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 130px;
`;
var Footer = import_styled_components.default.footer`
  background-color: var(--color-neutral-200);
  padding: var(--spacing-small);
`;
var Card = ({ image, onClick, invalidMessage }) => {
  const { id, name, url, width, height, size, format, updatedAt } = image;
  const isDisabled = Boolean(invalidMessage);
  return /* @__PURE__ */ (0, import_jsx_runtime.jsxs)(
    Root,
    {
      onClick: () => isDisabled ? null : onClick(image),
      isDisabled,
      children: [
        /* @__PURE__ */ (0, import_jsx_runtime.jsxs)(ImageWrapper, { children: [
          /* @__PURE__ */ (0, import_jsx_runtime.jsx)(ImageSource, { src: url }),
          invalidMessage && /* @__PURE__ */ (0, import_jsx_runtime.jsx)(InvalidMessage, { children: invalidMessage })
        ] }),
        /* @__PURE__ */ (0, import_jsx_runtime.jsx)(Footer, { children: /* @__PURE__ */ (0, import_jsx_runtime.jsxs)(import_sonar.Stack, { spacing: "x-small", children: [
          /* @__PURE__ */ (0, import_jsx_runtime.jsxs)(import_sonar.Row, { fluid: true, xAlign: "space-between", children: [
            /* @__PURE__ */ (0, import_jsx_runtime.jsx)(Title, { weight: "bold", title: name, children: name }),
            /* @__PURE__ */ (0, import_jsx_runtime.jsx)(import_sonar.Text, { weight: "bold", size: "small", children: formatDate(updatedAt) })
          ] }),
          /* @__PURE__ */ (0, import_jsx_runtime.jsxs)(import_sonar.Row, { fluid: true, xAlign: "space-between", children: [
            /* @__PURE__ */ (0, import_jsx_runtime.jsxs)(import_sonar.Row, { yAlign: "center", spacing: "x-small", children: [
              /* @__PURE__ */ (0, import_jsx_runtime.jsx)(import_sonar.Icon, { size: "x-small", icon: import_faImage.faImage }),
              /* @__PURE__ */ (0, import_jsx_runtime.jsxs)(import_sonar.Text, { size: "small", children: [
                width,
                " x ",
                height,
                " / ",
                format.toUpperCase()
              ] })
            ] }),
            /* @__PURE__ */ (0, import_jsx_runtime.jsx)(import_sonar.Text, { size: "small", children: formatSize(size) })
          ] })
        ] }) })
      ]
    },
    id
  );
};

// src/components/Gallery/EmptyState.tsx
var import_styled_components2 = __toESM(require("styled-components"));
var import_sonar2 = require("@appcues/sonar");

// src/components/Gallery/EmptyIcon.tsx
var import_jsx_runtime2 = require("react/jsx-runtime");
var EmptyIcon = () => {
  return /* @__PURE__ */ (0, import_jsx_runtime2.jsxs)(
    "svg",
    {
      xmlns: "http://www.w3.org/2000/svg",
      fill: "none",
      width: "160",
      height: "160",
      children: [
        /* @__PURE__ */ (0, import_jsx_runtime2.jsxs)("g", { clipPath: "url(#a)", children: [
          /* @__PURE__ */ (0, import_jsx_runtime2.jsx)(
            "path",
            {
              fill: "color(display-p3 .9569 .9843 1)",
              d: "M80.722 160.838c44.182 0 80-35.817 80-80 0-44.182-35.818-80-80-80-44.183 0-80 35.818-80 80 0 44.183 35.817 80 80 80Z"
            }
          ),
          /* @__PURE__ */ (0, import_jsx_runtime2.jsx)(
            "path",
            {
              fill: "#fff",
              stroke: "color(display-p3 .3608 .3608 1)",
              strokeLinecap: "round",
              strokeLinejoin: "round",
              d: "M19.105 94.555a.14.14 0 0 1 .106-.094l1.874-.512a.14.14 0 0 0 .105-.135.144.144 0 0 0-.105-.14l-1.874-.516a.152.152 0 0 1-.101-.102l-.517-1.875a.14.14 0 0 0-.135-.106.144.144 0 0 0-.14.106l-.511 1.875a.147.147 0 0 1-.097.102l-1.883.512a.135.135 0 0 0-.105.135.14.14 0 0 0 .106.136l1.882.52a.153.153 0 0 1 .101.102l.508 1.875a.148.148 0 0 0 .14.106.14.14 0 0 0 .134-.106l.512-1.883Z"
            }
          ),
          /* @__PURE__ */ (0, import_jsx_runtime2.jsx)(
            "path",
            {
              fill: "color(display-p3 .9137 .9686 1)",
              d: "M15.722 127.838h130v40.405h-130z"
            }
          ),
          /* @__PURE__ */ (0, import_jsx_runtime2.jsx)(
            "path",
            {
              stroke: "color(display-p3 .3608 .3608 1)",
              strokeLinecap: "round",
              strokeWidth: ".878",
              d: "M24.945 128.278h111.554m4.391 0h4.392m-129.121 0h4.392"
            }
          )
        ] }),
        /* @__PURE__ */ (0, import_jsx_runtime2.jsx)(
          "path",
          {
            fill: "#fff",
            stroke: "color(display-p3 .3608 .3608 1)",
            strokeLinecap: "round",
            strokeLinejoin: "round",
            d: "M130.215 42.568a.413.413 0 0 1 .31-.273l5.482-1.498a.407.407 0 0 0 .309-.397.422.422 0 0 0-.309-.409l-5.482-1.51a.448.448 0 0 1-.297-.297l-1.51-5.486a.407.407 0 0 0-.396-.31.424.424 0 0 0-.408.31l-1.497 5.486a.437.437 0 0 1-.285.297l-5.507 1.498a.395.395 0 0 0-.309.397.41.41 0 0 0 .309.396l5.507 1.523a.448.448 0 0 1 .297.297l1.485 5.486a.433.433 0 0 0 .408.31.406.406 0 0 0 .396-.31l1.497-5.51Z"
          }
        ),
        /* @__PURE__ */ (0, import_jsx_runtime2.jsx)(
          "path",
          {
            fill: "url(#b)",
            stroke: "color(display-p3 .3608 .3608 1)",
            strokeLinecap: "round",
            strokeLinejoin: "round",
            d: "M69.959 17.271a.519.519 0 0 1 .393-.346l6.966-1.904a.519.519 0 0 0 .393-.504.535.535 0 0 0-.393-.52l-6.966-1.92a.567.567 0 0 1-.378-.377l-1.918-6.972a.519.519 0 0 0-.504-.394.534.534 0 0 0-.519.394L65.131 11.7a.551.551 0 0 1-.362.378l-6.998 1.904a.504.504 0 0 0-.393.504.52.52 0 0 0 .393.503l6.998 1.936a.566.566 0 0 1 .377.378l1.887 6.972a.551.551 0 0 0 .52.394.519.519 0 0 0 .503-.394l1.903-7.004Z"
          }
        ),
        /* @__PURE__ */ (0, import_jsx_runtime2.jsx)(
          "path",
          {
            fill: "#fff",
            stroke: "color(display-p3 .3608 .3608 1)",
            d: "m58.399 111.856-.003.001a1.416 1.416 0 0 1-1.736-.996v-.002L42.394 56.753l-.002-.007a1.4 1.4 0 0 1 .995-1.731L95.28 41.383a1.417 1.417 0 0 1 1.724 1.01l12.224 46.22c1.342 5.075-1.686 10.275-6.761 11.615l-44.068 11.628Z"
          }
        ),
        /* @__PURE__ */ (0, import_jsx_runtime2.jsx)(
          "path",
          {
            fill: "#fff",
            stroke: "color(display-p3 .3608 .3608 1)",
            strokeLinejoin: "round",
            d: "M102.07 86.577 61.676 97.242a1.933 1.933 0 0 1-2.4-1.35l-8.999-34.095a1.916 1.916 0 0 1 1.367-2.333l38.595-10.232a1.933 1.933 0 0 1 2.35 1.366l9.481 35.979Z"
          }
        ),
        /* @__PURE__ */ (0, import_jsx_runtime2.jsx)(
          "path",
          {
            fill: "#fff",
            stroke: "color(display-p3 .3608 .3608 1)",
            d: "M63.776 61.547h54.159c.644 0 1.167.522 1.167 1.166v56.459c0 .644-.523 1.167-1.167 1.167h-54.16a1.167 1.167 0 0 1-1.166-1.167V62.713c0-.644.523-1.166 1.167-1.166Z"
          }
        ),
        /* @__PURE__ */ (0, import_jsx_runtime2.jsx)(
          "path",
          {
            fill: "color(display-p3 .8431 .8431 1)",
            stroke: "color(display-p3 .3608 .3608 1)",
            d: "M110.972 67.713H71.176a2.14 2.14 0 0 0-2.143 2.138V105a2.14 2.14 0 0 0 2.143 2.138h39.796a2.14 2.14 0 0 0 2.143-2.138V69.851a2.14 2.14 0 0 0-2.143-2.138Z"
          }
        ),
        /* @__PURE__ */ (0, import_jsx_runtime2.jsx)(
          "mask",
          {
            id: "c",
            width: "44",
            height: "39",
            x: "69",
            y: "68",
            maskUnits: "userSpaceOnUse",
            style: { maskType: "alpha" },
            children: /* @__PURE__ */ (0, import_jsx_runtime2.jsx)(
              "path",
              {
                fill: "color(display-p3 .8431 .8431 1)",
                stroke: "color(display-p3 .3608 .3608 1)",
                d: "M71.176 68.713h39.796a1.14 1.14 0 0 1 1.143 1.138V105a1.14 1.14 0 0 1-1.143 1.138H71.176A1.14 1.14 0 0 1 70.033 105V69.851c0-.627.51-1.138 1.143-1.138Z"
              }
            )
          }
        ),
        /* @__PURE__ */ (0, import_jsx_runtime2.jsxs)("g", { mask: "url(#c)", children: [
          /* @__PURE__ */ (0, import_jsx_runtime2.jsx)(
            "path",
            {
              fill: "#fff",
              stroke: "color(display-p3 .3608 .3608 1)",
              strokeLinejoin: "round",
              d: "M119.168 107.22s-9.782-8.959-11.299-16.25a2.233 2.233 0 0 0-.796-1.294 2.31 2.31 0 0 0-1.453-.504h-9.666a2.311 2.311 0 0 0-1.488.512c-.418.338-.7.812-.795 1.335-1.383 7.275-10.165 16.201-10.165 16.201h35.662Z"
            }
          ),
          /* @__PURE__ */ (0, import_jsx_runtime2.jsx)("mask", { id: "d", fill: "#fff", children: /* @__PURE__ */ (0, import_jsx_runtime2.jsx)(
            "path",
            {
              fillRule: "evenodd",
              d: "M67.173 83.35c-1.196 0-2.297-.8-2.297-1.996a7.319 7.319 0 0 1 13.904-3.197.567.567 0 0 0 .512.314c1.284 0 2.434.574 3.207 1.48.608.713 1.622 1.181 2.56 1.181 2.052 0 1.223 2.218-.829 2.218H67.173Z",
              clipRule: "evenodd"
            }
          ) }),
          /* @__PURE__ */ (0, import_jsx_runtime2.jsx)(
            "path",
            {
              fill: "#fff",
              fillRule: "evenodd",
              d: "M67.173 83.35c-1.196 0-2.297-.8-2.297-1.996a7.319 7.319 0 0 1 13.904-3.197.567.567 0 0 0 .512.314c1.284 0 2.434.574 3.207 1.48.608.713 1.622 1.181 2.56 1.181 2.052 0 1.223 2.218-.829 2.218H67.173Z",
              clipRule: "evenodd"
            }
          ),
          /* @__PURE__ */ (0, import_jsx_runtime2.jsx)(
            "path",
            {
              fill: "color(display-p3 .3608 .3608 1)",
              d: "m78.78 78.157-.899.437.9-.437Zm3.72 1.793-.761.65.76-.65Zm-16.623 1.404a6.32 6.32 0 0 1 6.319-6.32v-2a8.319 8.319 0 0 0-8.319 8.32h2Zm6.319-6.32a6.32 6.32 0 0 1 5.686 3.56l1.799-.875a8.32 8.32 0 0 0-7.485-4.684v2Zm7.097 4.437c.979 0 1.855.436 2.446 1.129l1.521-1.299a5.205 5.205 0 0 0-3.967-1.83v2Zm4.938 2.879H67.173v2H84.23v-2Zm.829-.218c.36 0 .353.1.266-.001-.114-.133-.07-.262-.079-.24-.012.034-.078.15-.26.263a1.422 1.422 0 0 1-.756.196v2c1.36 0 2.51-.74 2.89-1.76.199-.531.193-1.215-.278-1.763-.445-.517-1.119-.695-1.783-.695v2Zm-7.178-3.538c.27.556.832.877 1.41.877v-2c.148 0 .307.081.389.248l-1.799.875Zm3.857 2.006c.798.935 2.09 1.532 3.32 1.532v-2c-.644 0-1.38-.34-1.799-.83L81.738 80.6Zm-17.862.754c0 1.921 1.74 2.996 3.297 2.996v-2c-.835 0-1.297-.526-1.297-.996h-2Z",
              mask: "url(#d)"
            }
          ),
          /* @__PURE__ */ (0, import_jsx_runtime2.jsx)("mask", { id: "e", fill: "#fff", children: /* @__PURE__ */ (0, import_jsx_runtime2.jsx)(
            "path",
            {
              fillRule: "evenodd",
              d: "M77.652 79.857c-1.196 0-2.297-.8-2.297-1.996a7.319 7.319 0 0 1 13.904-3.197.567.567 0 0 0 .512.313c1.284 0 2.433.575 3.206 1.48.61.714 1.623 1.182 2.56 1.182 2.052 0 1.224 2.218-.828 2.218H77.652Z",
              clipRule: "evenodd"
            }
          ) }),
          /* @__PURE__ */ (0, import_jsx_runtime2.jsx)(
            "path",
            {
              fill: "#fff",
              fillRule: "evenodd",
              d: "M77.652 79.857c-1.196 0-2.297-.8-2.297-1.996a7.319 7.319 0 0 1 13.904-3.197.567.567 0 0 0 .512.313c1.284 0 2.433.575 3.206 1.48.61.714 1.623 1.182 2.56 1.182 2.052 0 1.224 2.218-.828 2.218H77.652Z",
              clipRule: "evenodd"
            }
          ),
          /* @__PURE__ */ (0, import_jsx_runtime2.jsx)(
            "path",
            {
              fill: "color(display-p3 .3608 .3608 1)",
              d: "m89.26 74.664.899-.438-.9.438Zm3.717 1.793-.76.65.76-.65Zm-16.622 1.404a6.32 6.32 0 0 1 6.319-6.32v-2a8.319 8.319 0 0 0-8.319 8.32h2Zm6.319-6.32a6.32 6.32 0 0 1 5.686 3.56l1.799-.875a8.32 8.32 0 0 0-7.485-4.684v2Zm7.097 4.436c.979 0 1.855.437 2.446 1.13l1.521-1.299a5.205 5.205 0 0 0-3.967-1.83v2Zm4.938 2.88H77.652v2h17.057v-2Zm.828-.218c.362 0 .353.099.267-.002-.114-.132-.07-.262-.079-.24-.012.035-.079.15-.26.264a1.423 1.423 0 0 1-.756.196v2c1.36 0 2.51-.74 2.89-1.76.199-.531.193-1.215-.279-1.764-.444-.516-1.118-.694-1.782-.694v2ZM88.36 75.1c.27.555.832.876 1.41.876v-2c.148 0 .307.082.389.25l-1.799.874Zm3.857 2.005c.798.936 2.09 1.533 3.32 1.533v-2c-.644 0-1.38-.34-1.799-.831l-1.521 1.298Zm-17.862.755c0 1.921 1.74 2.996 3.297 2.996v-2c-.835 0-1.297-.526-1.297-.996h-2Z",
              mask: "url(#e)"
            }
          ),
          /* @__PURE__ */ (0, import_jsx_runtime2.jsx)(
            "path",
            {
              fill: "#fff",
              stroke: "color(display-p3 .3608 .3608 1)",
              d: "M108.622 76.364a4.157 4.157 0 1 1-8.314 0 4.157 4.157 0 0 1 8.314 0Z"
            }
          ),
          /* @__PURE__ */ (0, import_jsx_runtime2.jsx)(
            "path",
            {
              fill: "#fff",
              stroke: "color(display-p3 .3608 .3608 1)",
              strokeLinejoin: "round",
              d: "M107.376 90.918c0 .965-2.998 1.747-6.695 1.747-3.698 0-6.696-.782-6.696-1.747 0-1.746 2.998-1.746 6.696-1.746 3.697 0 6.695 0 6.695 1.746Z"
            }
          )
        ] }),
        /* @__PURE__ */ (0, import_jsx_runtime2.jsxs)("defs", { children: [
          /* @__PURE__ */ (0, import_jsx_runtime2.jsxs)(
            "linearGradient",
            {
              id: "b",
              x1: "57.377",
              x2: "77.711",
              y1: "14.502",
              y2: "14.502",
              gradientUnits: "userSpaceOnUse",
              children: [
                /* @__PURE__ */ (0, import_jsx_runtime2.jsx)("stop", { stopColor: "color(display-p3 .3608 .3608 1)" }),
                /* @__PURE__ */ (0, import_jsx_runtime2.jsx)("stop", { offset: "1", stopColor: "color(display-p3 .5373 .3765 1)" })
              ]
            }
          ),
          /* @__PURE__ */ (0, import_jsx_runtime2.jsx)("clipPath", { id: "a", children: /* @__PURE__ */ (0, import_jsx_runtime2.jsx)(
            "rect",
            {
              width: "160",
              height: "160",
              x: ".722",
              y: ".838",
              fill: "#fff",
              rx: "80"
            }
          ) })
        ] })
      ]
    }
  );
};

// src/components/Gallery/EmptyState.tsx
var import_jsx_runtime3 = require("react/jsx-runtime");
var Wrapper = (0, import_styled_components2.default)(import_sonar2.Stack)`
  text-align: center;
  margin: var(--spacing-regular) auto;
`;
var EmptyState = ({ isLoading }) => {
  return /* @__PURE__ */ (0, import_jsx_runtime3.jsx)(Wrapper, { xAlign: "center", yAlign: "center", spacing: "regular", children: isLoading ? /* @__PURE__ */ (0, import_jsx_runtime3.jsx)(import_sonar2.Spinner, {}) : /* @__PURE__ */ (0, import_jsx_runtime3.jsxs)(import_jsx_runtime3.Fragment, { children: [
    /* @__PURE__ */ (0, import_jsx_runtime3.jsx)(EmptyIcon, {}),
    /* @__PURE__ */ (0, import_jsx_runtime3.jsx)(import_sonar2.SubTitle, { children: "Empty Gallery" }),
    /* @__PURE__ */ (0, import_jsx_runtime3.jsxs)(import_sonar2.Text, { children: [
      "Once you upload images to any Appcues experience, ",
      /* @__PURE__ */ (0, import_jsx_runtime3.jsx)("br", {}),
      "you will see them appear here."
    ] })
  ] }) });
};

// src/components/Gallery/Grid.tsx
var import_styled_components3 = __toESM(require("styled-components"));
var import_jsx_runtime4 = require("react/jsx-runtime");
var StyledGrid = import_styled_components3.default.div`
  display: grid;
  grid-template-columns: ${({ $hasItems }) => $hasItems ? `repeat(3, 1fr)` : `1fr`};
  gap: var(--spacing-regular);
  max-height: 500px;
  min-height: 235px;
  overflow-y: auto;
  padding: var(--spacing-small);
  border: 1px solid var(--border-default);
  border-radius: var(--border-radius-small);
`;
var Grid = ({ children, hasItems }) => /* @__PURE__ */ (0, import_jsx_runtime4.jsx)(StyledGrid, { $hasItems: hasItems, children });

// src/components/validators.ts
var getFileInfo = (file) => {
  if ("type" in file) {
    return {
      type: file.type?.toLowerCase(),
      extension: `.${file?.name?.toLowerCase().split(".").pop()}`
    };
  }
  return {
    extension: file.format
  };
};
var validateFormat = (file, accept) => {
  if (!file || !accept) {
    return false;
  }
  const acceptedTypes = accept.split(",").map((format) => format.trim().toLowerCase());
  const { type, extension } = getFileInfo(file);
  const isValid = acceptedTypes.some((acceptedType) => {
    if (type === acceptedType) {
      return true;
    }
    if (type?.startsWith("image/") && acceptedType === "image/*") {
      return true;
    }
    const acceptedExtension = acceptedType.split("/")[1];
    if (acceptedExtension === extension) {
      return true;
    }
    if (acceptedExtension === "*") {
      return true;
    }
    return false;
  });
  return isValid;
};
var validateDimension = (image, maxDimension) => {
  return !maxDimension || image.width <= maxDimension && image.height <= maxDimension;
};
var validateSize = (file, maxSize) => {
  return !maxSize || file.size <= maxSize * 1024 * 1024;
};

// src/components/Gallery/image-validator.ts
var validateImage = ({
  image,
  accept,
  maxSize,
  maxDimension
}) => {
  const validations = [
    {
      isValid: validateFormat(image, accept),
      message: "format"
    },
    {
      isValid: validateSize(image, maxSize),
      message: "size"
    },
    {
      isValid: validateDimension(image, maxDimension),
      message: "dimension"
    }
  ];
  const invalidMessages = validations.filter((validation) => !validation.isValid).map((validation) => validation.message);
  const isValid = invalidMessages.length === 0;
  const tooltipContent = !isValid ? `Invalid ${invalidMessages.join(", ").replace(/, ([^,]*)$/, " and $1")}` : "";
  return { status: isValid, invalidMessage: tooltipContent };
};

// src/components/Gallery/Gallery.tsx
var import_jsx_runtime5 = require("react/jsx-runtime");
var Gallery = ({
  accept = "image/*",
  maxSize = 1,
  maxDimension,
  container,
  onSelect
}) => {
  const sentinelRef = (0, import_react2.useRef)(null);
  const {
    images,
    isLoading,
    isGalleryOpen,
    setIsGalleryOpen,
    pageInfo,
    fetchImages
  } = useImagery();
  const { number, size, total } = pageInfo;
  const hasItems = total > 0;
  (0, import_react2.useEffect)(() => {
    if (isGalleryOpen) {
      fetchImages(1);
    }
  }, [isGalleryOpen, fetchImages]);
  const fetchNextPage = (0, import_react2.useCallback)(() => {
    if (number * size < total) {
      const nextPage = number + 1;
      fetchImages(nextPage);
    }
  }, [number, size, total, fetchImages]);
  (0, import_react2.useEffect)(() => {
    const currentSentinel = sentinelRef.current;
    if (!currentSentinel) return;
    const observerInstance = new IntersectionObserver(
      (entries) => {
        const [{ isIntersecting }] = entries;
        if (isIntersecting) {
          fetchNextPage();
        }
      },
      { threshold: 0.1 }
    );
    observerInstance.observe(currentSentinel);
    return () => {
      if (currentSentinel) {
        observerInstance.unobserve(currentSentinel);
      }
      observerInstance.disconnect();
    };
  }, [fetchNextPage]);
  const handleClick = (image) => {
    onSelect?.(image);
    setIsGalleryOpen(false);
  };
  return /* @__PURE__ */ (0, import_jsx_runtime5.jsxs)(
    import_sonar3.Modal.Root,
    {
      open: isGalleryOpen,
      onOpenChange: () => setIsGalleryOpen(false),
      container,
      size: "large",
      children: [
        /* @__PURE__ */ (0, import_jsx_runtime5.jsx)(import_sonar3.Modal.Title, { children: "Image Gallery" }),
        /* @__PURE__ */ (0, import_jsx_runtime5.jsx)(Grid, { hasItems, children: hasItems ? /* @__PURE__ */ (0, import_jsx_runtime5.jsxs)(import_jsx_runtime5.Fragment, { children: [
          images.map((image) => {
            const { invalidMessage } = validateImage({
              image,
              accept,
              maxSize,
              maxDimension
            });
            return /* @__PURE__ */ (0, import_jsx_runtime5.jsx)(
              Card,
              {
                image,
                onClick: handleClick,
                invalidMessage
              },
              image.id
            );
          }),
          /* @__PURE__ */ (0, import_jsx_runtime5.jsx)(
            "div",
            {
              ref: sentinelRef,
              className: "col-span-full h-4",
              "aria-hidden": "true"
            }
          )
        ] }) : /* @__PURE__ */ (0, import_jsx_runtime5.jsx)(EmptyState, { isLoading }) }),
        /* @__PURE__ */ (0, import_jsx_runtime5.jsx)(import_sonar3.Modal.Close, { "aria-label": "Close", children: /* @__PURE__ */ (0, import_jsx_runtime5.jsx)(import_sonar3.Icon, { size: "large", icon: import_faXmark.faXmark }) })
      ]
    }
  );
};

// src/components/Uploader/Uploader.tsx
var import_react3 = require("react");
var import_faCirclePlus = __toESM(require_faCirclePlus());
var import_faUpload = __toESM(require_faUpload());
var import_faImages = __toESM(require_faImages());
var import_faTriangleExclamation = __toESM(require_faTriangleExclamation());
var import_faHexagonExclamation = __toESM(require_faHexagonExclamation());
var import_sonar7 = require("@appcues/sonar");

// src/components/Uploader/SelectedImage.tsx
var import_faTrashCan = __toESM(require_faTrashCan());
var import_sonar4 = require("@appcues/sonar");
var import_styled_components4 = __toESM(require("styled-components"));
var import_jsx_runtime6 = require("react/jsx-runtime");
var TitleText = (0, import_styled_components4.default)(import_sonar4.Text)`
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  min-width: 0;
  text-align: left;
`;
var SelectedImage = ({ image, handleClean }) => {
  return /* @__PURE__ */ (0, import_jsx_runtime6.jsxs)(import_sonar4.Row, { spacing: "small", yAlign: "center", xAlign: "space-between", children: [
    /* @__PURE__ */ (0, import_jsx_runtime6.jsx)(TitleText, { weight: "bold", colorToken: "foreground-primary", children: image }),
    /* @__PURE__ */ (0, import_jsx_runtime6.jsx)(
      import_sonar4.Button,
      {
        variant: "tertiary",
        iconOnly: true,
        onClick: handleClean,
        "aria-label": "remove file",
        children: /* @__PURE__ */ (0, import_jsx_runtime6.jsx)(
          import_sonar4.Icon,
          {
            icon: import_faTrashCan.faTrashCan,
            colorToken: "background-button-destructive-default"
          }
        )
      }
    )
  ] });
};

// src/components/Uploader/LoadingIcon.tsx
var import_styled_components5 = __toESM(require("styled-components"));

// ../../node_modules/@fortawesome/free-solid-svg-icons/index.mjs
var faArrowUp = {
  prefix: "fas",
  iconName: "arrow-up",
  icon: [384, 512, [8593], "f062", "M214.6 41.4c-12.5-12.5-32.8-12.5-45.3 0l-160 160c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L160 141.2V448c0 17.7 14.3 32 32 32s32-14.3 32-32V141.2L329.4 246.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3l-160-160z"]
};

// src/components/Uploader/LoadingIcon.tsx
var import_sonar5 = require("@appcues/sonar");
var import_jsx_runtime7 = require("react/jsx-runtime");
var StyledLoadingIcon = import_styled_components5.default.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;

  @keyframes rotate {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }

  svg:first-child {
    animation: rotate 1s linear infinite;
    transform-origin: center;
  }

  ${import_sonar5.Icon} {
    position: absolute;
  }
`;
var LoadingIcon = () => /* @__PURE__ */ (0, import_jsx_runtime7.jsxs)(StyledLoadingIcon, { children: [
  /* @__PURE__ */ (0, import_jsx_runtime7.jsxs)(
    "svg",
    {
      xmlns: "http://www.w3.org/2000/svg",
      width: "24",
      height: "24",
      viewBox: "0 0 24 24",
      fill: "none",
      children: [
        /* @__PURE__ */ (0, import_jsx_runtime7.jsx)(
          "path",
          {
            d: "M24 12C24 14.5342 23.1977 17.0033 21.7082 19.0534C20.2187 21.1036 18.1183 22.6296 15.7082 23.4127C13.2981 24.1958 10.7019 24.1958 8.2918 23.4127C5.88167 22.6296 3.78133 21.1036 2.2918 19.0534C0.802259 17.0033 -2.21542e-07 14.5342 0 12C2.21543e-07 9.46585 0.80226 6.99675 2.2918 4.94658C3.78133 2.8964 5.88168 1.37042 8.2918 0.587321C10.7019 -0.195774 13.2981 -0.195774 15.7082 0.587322L15.1149 2.41335C13.0904 1.75555 10.9096 1.75555 8.88511 2.41335C6.86061 3.07115 5.09632 4.35298 3.84511 6.07512C2.5939 7.79727 1.92 9.87131 1.92 12C1.92 14.1287 2.5939 16.2027 3.84511 17.9249C5.09632 19.647 6.86061 20.9288 8.88511 21.5866C10.9096 22.2444 13.0904 22.2445 15.1149 21.5866C17.1394 20.9288 18.9037 19.647 20.1549 17.9249C21.4061 16.2027 22.08 14.1287 22.08 12H24Z",
            fill: "#DCE4F2",
            id: "circlePath"
          }
        ),
        /* @__PURE__ */ (0, import_jsx_runtime7.jsx)(
          "path",
          {
            id: "movingPath",
            d: "M12 0C13.5759 1.8792e-08 15.1363 0.310389 16.5922 0.913446C18.0481 1.5165 19.371 2.40042 20.4853 3.51472C21.5996 4.62902 22.4835 5.95189 23.0866 7.4078C23.6896 8.86371 24 10.4241 24 12L22.08 12C22.08 10.6763 21.8193 9.36551 21.3127 8.14255C20.8061 6.91959 20.0637 5.80838 19.1276 4.87236C18.1916 3.93635 17.0804 3.19386 15.8574 2.68729C14.6345 2.18073 13.3237 1.92 12 1.92L12 0Z",
            fill: "#0056A1"
          }
        )
      ]
    }
  ),
  /* @__PURE__ */ (0, import_jsx_runtime7.jsx)(import_sonar5.Icon, { icon: faArrowUp, colorToken: "foreground-primary", size: "x-small" })
] });

// src/components/Uploader/state.ts
var initialState = {
  status: "idle"
};
function reducer(state, action) {
  switch (action.type) {
    case "DRAG_OVER":
      return { status: "focus" };
    case "DRAG_LEAVE":
      return { status: "idle" };
    case "START_UPLOAD":
      return { status: "uploading" };
    case "UPLOAD_FINISHED":
      return { status: "showing_image", image: action.image };
    case "INVALIDATE_IMAGE":
      return { status: "warning", warningMessage: action.warningMessage };
    case "UPLOAD_ERROR":
      return { status: "error", errorMessage: action.errorMessage };
    case "CLEAN_FILE":
      return { status: "idle" };
    case "RESET_STATUS":
      return { status: "idle" };
    case "IMAGE_SELECTED":
      return { status: "showing_image", image: action.image };
    default:
      return state;
  }
}

// src/components/Uploader/file-validator.ts
var validateFileDimension = async (file, maxDimension) => {
  if (!maxDimension) return true;
  try {
    const bitmap = await createImageBitmap(file);
    const isValid = validateDimension(bitmap, maxDimension);
    bitmap.close();
    return isValid;
  } catch (error) {
    console.error("Failed to create image bitmap:", error);
    return false;
  }
};

// src/components/Uploader/styled.tsx
var import_styled_components6 = __toESM(require("styled-components"));
var import_sonar6 = require("@appcues/sonar");
var UploaderBox = import_styled_components6.default.button`
  width: 100%;
  padding: var(--spacing-regular);
  border-radius: var(--border-radius-small);

  // Focus in this case changes background and border color no need for outline
  outline: none;

  // Applies to idle or loading by default
  background: var(--background-default-base);
  border: 1px dotted var(--border-input-default);

  // Override dropdown menu button styles
  &&[data-state='open'] {
    background: var(--background-brand-base);
    border: 1px dotted var(--border-input-default);
  }

  ${(props) => props.status !== "showing_image" && `    
      cursor: pointer;

      &:hover,
      &:focus {
        background: var(--background-brand-base);
      }
    `}

  ${(props) => props.status === "warning" && `
    background: var(--background-warning);
    border: 1px dashed var(--border-warning);

    &:hover,
    &:focus {
      background: var(--background-warning);
      border: 1px dashed var(--border-warning);
    }
  `}

  ${(props) => props.status === "error" && `
    background: var(--background-error-base);
    border: 1px dashed var(--border-error);

    &:hover,
    &:focus {
      background: var(--background-error-base);
      border: 1px dashed var(--border-error);
    }
  `}

  ${(props) => props.status === "focus" && `
    background: var(--color-blue-100);
  `}

  > input {
    position: absolute;
    height: 0;
    width: 0;
    visibility: hidden;
  }
`;
var BackButton = (0, import_styled_components6.default)(import_sonar6.Button)`
  && {
    padding: 2px var(--spacing-regular);

    &:hover:not(:active):not([disabled]),
    &:focus:not(:active):not([disabled]) {
      background: transparent;
    }
  }
`;

// src/components/Uploader/Uploader.tsx
var import_jsx_runtime8 = require("react/jsx-runtime");
var Uploader = ({
  title = "Add an image",
  description = "JPEG, PNG and SVG - 1MB Max",
  accept = "image/*",
  maxSize = 1,
  maxDimension,
  selectedImage,
  disableMenu,
  onUpload,
  onClean,
  ...htmlAttrs
}) => {
  const { setIsGalleryOpen } = useImagery();
  const inputId = (0, import_react3.useMemo)(() => `imagery-uploader-${crypto.randomUUID()}`, []);
  const inputRef = (0, import_react3.useRef)(null);
  const [state, dispatch] = (0, import_react3.useReducer)(
    reducer,
    selectedImage ? { status: "showing_image", image: selectedImage } : initialState
  );
  (0, import_react3.useEffect)(() => {
    if (selectedImage) {
      dispatch({
        type: "IMAGE_SELECTED",
        image: selectedImage
      });
    }
  }, [selectedImage]);
  const [openDropdownMenu, setDropdownOpen] = (0, import_react3.useState)(false);
  const validateImage2 = async (file) => {
    if (!validateFormat(file, accept)) {
      dispatch({
        type: "INVALIDATE_IMAGE",
        warningMessage: `Format ${file.type} is not supported`
      });
      return false;
    }
    if (!validateSize(file, maxSize)) {
      dispatch({
        type: "INVALIDATE_IMAGE",
        warningMessage: `Size must not exceed ${maxSize}MB`
      });
      return false;
    }
    if (!await validateFileDimension(file, maxDimension)) {
      dispatch({
        type: "INVALIDATE_IMAGE",
        warningMessage: `Dimensions must not exceed ${maxDimension}px`
      });
      return false;
    }
    return true;
  };
  const handleOnUpload = async (image) => {
    try {
      const isImageValid = await validateImage2(image);
      if (!isImageValid) return;
      dispatch({ type: "START_UPLOAD" });
      const result = await onUpload(image);
      if (!result) {
        throw new Error("Upload failed");
      }
      dispatch({
        type: "UPLOAD_FINISHED",
        image: result?.url
      });
    } catch (error) {
      dispatch({
        type: "UPLOAD_ERROR",
        errorMessage: error instanceof Error ? error.message : "Unknown error occurred"
      });
    }
  };
  const handleOnDrop = async (event) => {
    event.preventDefault();
    if (event.dataTransfer.files.length === 0) return;
    await handleOnUpload(event.dataTransfer.files[0]);
  };
  const handleDragOver = (event) => {
    event.preventDefault();
    dispatch({
      type: "DRAG_OVER"
    });
  };
  const handleDragLeave = (event) => {
    event.preventDefault();
    dispatch({
      type: "DRAG_LEAVE"
    });
  };
  const handleOnChange = async (event) => {
    if (!event.target.files || event.target.files.length === 0) return;
    await handleOnUpload(event.target.files[0]);
  };
  const handleOnUploadSelect = () => {
    inputRef.current?.click();
  };
  const handleClean = () => {
    dispatch({
      type: "CLEAN_FILE"
    });
    onClean?.();
  };
  const handleBack = () => {
    dispatch({
      type: "RESET_STATUS"
    });
  };
  if (state.status === "showing_image") {
    return /* @__PURE__ */ (0, import_jsx_runtime8.jsx)(UploaderBox, { status: state.status, as: "div", children: /* @__PURE__ */ (0, import_jsx_runtime8.jsx)(SelectedImage, { image: state.image, handleClean }) });
  }
  if (state.status === "error") {
    return /* @__PURE__ */ (0, import_jsx_runtime8.jsx)(UploaderBox, { status: state.status, as: "div", children: /* @__PURE__ */ (0, import_jsx_runtime8.jsxs)(import_sonar7.Stack, { spacing: "small", xAlign: "center", children: [
      /* @__PURE__ */ (0, import_jsx_runtime8.jsxs)(import_sonar7.Row, { spacing: "small", yAlign: "center", children: [
        /* @__PURE__ */ (0, import_jsx_runtime8.jsx)(import_sonar7.Icon, { icon: import_faHexagonExclamation.faHexagonExclamation, colorToken: "foreground-error" }),
        /* @__PURE__ */ (0, import_jsx_runtime8.jsx)(import_sonar7.Text, { weight: "bold", colorToken: "foreground-error", children: state.errorMessage })
      ] }),
      /* @__PURE__ */ (0, import_jsx_runtime8.jsx)(BackButton, { variant: "tertiary", onClick: handleBack, children: "Back" })
    ] }) });
  }
  if (state.status === "warning") {
    return /* @__PURE__ */ (0, import_jsx_runtime8.jsx)(UploaderBox, { status: state.status, as: "div", children: /* @__PURE__ */ (0, import_jsx_runtime8.jsxs)(import_sonar7.Stack, { spacing: "small", xAlign: "center", children: [
      /* @__PURE__ */ (0, import_jsx_runtime8.jsxs)(import_sonar7.Row, { spacing: "small", yAlign: "center", children: [
        /* @__PURE__ */ (0, import_jsx_runtime8.jsx)(
          import_sonar7.Icon,
          {
            icon: import_faTriangleExclamation.faTriangleExclamation,
            colorToken: "foreground-warning"
          }
        ),
        /* @__PURE__ */ (0, import_jsx_runtime8.jsx)(import_sonar7.Text, { weight: "bold", colorToken: "foreground-warning", children: state.warningMessage })
      ] }),
      /* @__PURE__ */ (0, import_jsx_runtime8.jsx)(BackButton, { variant: "tertiary", onClick: handleBack, children: "Back" })
    ] }) });
  }
  if (state.status === "uploading") {
    return /* @__PURE__ */ (0, import_jsx_runtime8.jsx)(UploaderBox, { status: state.status, as: "div", children: /* @__PURE__ */ (0, import_jsx_runtime8.jsxs)(import_sonar7.Stack, { spacing: "small", xAlign: "center", children: [
      /* @__PURE__ */ (0, import_jsx_runtime8.jsx)(LoadingIcon, {}),
      /* @__PURE__ */ (0, import_jsx_runtime8.jsx)(import_sonar7.Text, { weight: "bold", colorToken: "foreground-primary", children: "Uploading file..." })
    ] }) });
  }
  return /* @__PURE__ */ (0, import_jsx_runtime8.jsxs)(
    import_sonar7.DropdownMenu.Root,
    {
      open: !disableMenu && openDropdownMenu,
      onOpenChange: setDropdownOpen,
      children: [
        /* @__PURE__ */ (0, import_jsx_runtime8.jsx)(import_sonar7.DropdownMenu.Trigger, { asChild: true, children: /* @__PURE__ */ (0, import_jsx_runtime8.jsxs)(
          UploaderBox,
          {
            ...htmlAttrs,
            status: state.status,
            onDrop: handleOnDrop,
            onDragOver: handleDragOver,
            onDragLeave: handleDragLeave,
            onClick: disableMenu ? handleOnUploadSelect : void 0,
            type: "button",
            children: [
              /* @__PURE__ */ (0, import_jsx_runtime8.jsx)(
                "input",
                {
                  ref: inputRef,
                  id: inputId,
                  onChange: handleOnChange,
                  accept,
                  type: "file",
                  "aria-hidden": true
                }
              ),
              /* @__PURE__ */ (0, import_jsx_runtime8.jsxs)(import_sonar7.Stack, { spacing: "small", xAlign: "center", children: [
                /* @__PURE__ */ (0, import_jsx_runtime8.jsxs)(import_sonar7.Row, { spacing: "small", yAlign: "center", children: [
                  /* @__PURE__ */ (0, import_jsx_runtime8.jsx)(import_sonar7.Icon, { icon: import_faCirclePlus.faCirclePlus, colorToken: "foreground-primary" }),
                  /* @__PURE__ */ (0, import_jsx_runtime8.jsx)(import_sonar7.Text, { weight: "bold", colorToken: "foreground-primary", children: title })
                ] }),
                /* @__PURE__ */ (0, import_jsx_runtime8.jsx)(import_sonar7.Text, { colorToken: "foreground-input-placeholder", children: description })
              ] })
            ]
          }
        ) }),
        /* @__PURE__ */ (0, import_jsx_runtime8.jsx)(import_sonar7.DropdownMenu.Portal, { children: /* @__PURE__ */ (0, import_jsx_runtime8.jsxs)(import_sonar7.DropdownMenu.Content, { side: "bottom", children: [
          /* @__PURE__ */ (0, import_jsx_runtime8.jsx)(import_sonar7.DropdownMenu.Item, { onClick: handleOnUploadSelect, children: /* @__PURE__ */ (0, import_jsx_runtime8.jsxs)(import_sonar7.Row, { spacing: "small", yAlign: "center", children: [
            /* @__PURE__ */ (0, import_jsx_runtime8.jsx)(import_sonar7.Icon, { icon: import_faUpload.faUpload, colorToken: "foreground-primary" }),
            " Upload image"
          ] }) }),
          /* @__PURE__ */ (0, import_jsx_runtime8.jsx)(import_sonar7.DropdownMenu.Item, { onClick: () => setIsGalleryOpen(true), children: /* @__PURE__ */ (0, import_jsx_runtime8.jsxs)(import_sonar7.Row, { spacing: "small", yAlign: "center", children: [
            /* @__PURE__ */ (0, import_jsx_runtime8.jsx)(import_sonar7.Icon, { icon: import_faImages.faImages, colorToken: "foreground-primary" }),
            "Select from gallery"
          ] }) })
        ] }) })
      ]
    }
  );
};

// src/components/Provider.tsx
var import_react4 = require("react");
var import_jsx_runtime9 = require("react/jsx-runtime");
var Provider = ({
  children,
  customerApiURL,
  jwt,
  accountId
}) => {
  const [images, setImages] = (0, import_react4.useState)([]);
  const [isLoading, setIsLoading] = (0, import_react4.useState)(false);
  const [isGalleryOpen, setIsGalleryOpen] = (0, import_react4.useState)(false);
  const [pageInfo, setPageInfo] = (0, import_react4.useState)({ number: 1, size: 50, total: 0 });
  const { size } = pageInfo;
  const fetchImages = (0, import_react4.useCallback)(
    async (number = 1) => {
      try {
        setIsLoading(true);
        const response = await fetch(
          `${customerApiURL}/v1/accounts/${accountId}/images?sort_key=created_at&sort_direction=desc&page_number=${number}&page_size=${size}`,
          {
            headers: {
              Authorization: `Bearer ${jwt}`,
              "Content-Type": "application/json"
            }
          }
        );
        if (!response.ok) throw new Error("Failed to fetch images");
        const { data, pageInfo: pageInfoResponse } = await response.json();
        const { number: numberResponse, total: totalResponse } = pageInfoResponse;
        setImages((prev) => number === 1 ? data : [...prev, ...data]);
        setPageInfo({
          number: numberResponse,
          size,
          total: totalResponse
        });
      } catch (error) {
        console.error("Error fetching images:", error);
      } finally {
        setIsLoading(false);
      }
    },
    [jwt, customerApiURL, accountId, size]
  );
  const uploadImage = (0, import_react4.useCallback)(
    async (originalFile) => {
      try {
        const data = new FormData();
        data.append("file", originalFile);
        const response = await fetch(
          `${customerApiURL}/v1/accounts/${accountId}/images/upload`,
          {
            method: "POST",
            body: data,
            headers: {
              Authorization: `Bearer ${jwt}`
            }
          }
        );
        if (!response.ok) {
          throw new Error(`Upload failed with status: ${response.status}`);
        }
        const result = await response.json();
        return result;
      } catch (error) {
        console.error("Image upload failed:", error);
        throw error;
      }
    },
    [customerApiURL, accountId, jwt]
  );
  const value = (0, import_react4.useMemo)(
    () => ({
      images,
      setImages,
      isLoading,
      setIsLoading,
      isGalleryOpen,
      setIsGalleryOpen,
      pageInfo,
      setPageInfo,
      fetchImages,
      uploadImage
    }),
    [images, isLoading, isGalleryOpen, pageInfo, uploadImage, fetchImages]
  );
  return /* @__PURE__ */ (0, import_jsx_runtime9.jsx)(Context_default.Provider, { value, children });
};
