import { useState, useMemo, useCallback } from 'react';
import { Context } from 'context';
import type { ProviderType, ImageType } from 'types';

export const Provider = ({
  children,
  customerApiURL,
  jwt,
  accountId,
}: ProviderType) => {
  const [images, setImages] = useState<ImageType[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isGalleryOpen, setIsGalleryOpen] = useState(false);
  const [pageInfo, setPageInfo] = useState({ number: 1, size: 50, total: 0 });

  const { size } = pageInfo;

  const fetchImages = useCallback(
    async (number = 1) => {
      try {
        setIsLoading(true);
        const response = await fetch(
          `${customerApiURL}/v1/accounts/${accountId}/images?sort_key=created_at&sort_direction=desc&page_number=${number}&page_size=${size}`,
          {
            headers: {
              Authorization: `Bearer ${jwt}`,
              'Content-Type': 'application/json',
            },
          }
        );

        if (!response.ok) throw new Error('Failed to fetch images');

        const { data, pageInfo: pageInfoResponse } = await response.json();
        const { number: numberResponse, total: totalResponse } =
          pageInfoResponse;

        setImages(prev => (number === 1 ? data : [...prev, ...data]));
        setPageInfo({
          number: numberResponse,
          size,
          total: totalResponse,
        });
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error('Error fetching images:', error);
      } finally {
        setIsLoading(false);
      }
    },
    [jwt, customerApiURL, accountId, size]
  );

  const uploadImage = useCallback(
    async (originalFile: File): Promise<ImageType | null> => {
      try {
        const data = new FormData();
        data.append('file', originalFile);

        const response = await fetch(
          `${customerApiURL}/v1/accounts/${accountId}/images/upload`,
          {
            method: 'POST',
            body: data,
            headers: {
              Authorization: `Bearer ${jwt}`,
            },
          }
        );

        if (!response.ok) {
          throw new Error(`Upload failed with status: ${response.status}`);
        }

        const result = await response.json();
        return result;
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error('Image upload failed:', error);
        throw error;
      }
    },
    [customerApiURL, accountId, jwt]
  );

  const value = useMemo(
    () => ({
      images,
      setImages,
      isLoading,
      setIsLoading,
      isGalleryOpen,
      setIsGalleryOpen,
      pageInfo,
      setPageInfo,
      fetchImages,
      uploadImage,
    }),
    [images, isLoading, isGalleryOpen, pageInfo, uploadImage, fetchImages]
  );

  return <Context.Provider value={value}>{children}</Context.Provider>;
};
