import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import {
  ImageryGallery,
  ImageryUploader,
  useImagery,
} from '@appcues/imagery-kit';
import { parseSvgFile } from './image-upload';

const ImageRecommendation = styled.p`
  color: var(--color-neutral-700);
  font-size: var(--font-size-small);
  margin-bottom: 0;
`;

const ImageUploader = ({ currentImage, description, onUpload }) => {
  const uploadDescription = description ?? 'Add a background image (optional).';

  const { uploadImage } = useImagery();

  const handleOnSelect = image => {
    onUpload({
      url: image.url,
      intrinsicSize: {
        width: image.width,
        height: image.height,
      },
    });
  };

  const handleOnUpload = async file => {
    const parsedSvgFile = await parseSvgFile(file);

    const data = new FormData();
    data.append('file', parsedSvgFile);

    const image = await uploadImage(file);
    handleOnSelect(image);

    return image;
  };

  const handleOnRemoveIcon = () => {
    onUpload(null);
  };

  return (
    <>
      <ImageryGallery onSelect={handleOnSelect} maxSize={5} />
      <ImageryUploader
        description={uploadDescription}
        maxSize={5}
        selectedImage={currentImage}
        onUpload={handleOnUpload}
        onClean={handleOnRemoveIcon}
      />
      <ImageRecommendation>
        Use images smaller than 1MB for better user experience
      </ImageRecommendation>
    </>
  );
};

ImageUploader.propTypes = {
  currentImage: PropTypes.string,
  description: PropTypes.string,
  onUpload: PropTypes.func,
};

export default ImageUploader;
